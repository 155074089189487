<template>
  <div>
    <!-- Status Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: label -->
        <b-col cols="12" md="4">
          <b-form-group label="Libellé" label-for="label">
            <b-form-input
              id="label"
              v-model="taxData.name"
              @input="$emit('update:name', $event)"
            />
          </b-form-group>
        </b-col>

        <!-- Field: rate -->
        <b-col cols="12" md="4">
          <b-form-group label="Pourcentage (%)" label-for="rate">
            <b-form-input
              id="rate"
              v-model="taxData.rate"
              @input="$emit('update:rate', $event)"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Type -->
        <b-col cols="12" md="4">
          <b-form-group
            label="Type de taxe"
            label-for="tax-type"
          >
            <v-select
              v-model="taxData.type"
              input-id="tax-type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="taxesOptions"
              :reduce="(val) => val.key"
              @input="$emit('update:type', $event)"
            />
          </b-form-group>
          <b-alert
            v-height-fade.appear
            variant="warning"
            :show="show"
            class="mb-2"
            style="max-height: 56px !important;"
          >
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              Cette taxe sera appliquée uniquement dans le cadre de <b>{{ taxData.type == 'facture' ? 'paiement de Facture' : 'paiement de Salaire' }}</b>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="submitData"
      :disabled="isLoading"
    >
      {{ taxData.id ? "Modifier" : "Sauvegarder" }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.go(-1)"
    >
      Annuler
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useTaxesList from "../taxes-list/useTaxesList";
import { computed, ref } from "@vue/composition-api";
import { heightFade } from '@core/directives/animations'

export default {
  name: "HandleTaxTabGeneral",
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BAlert,
    vSelect,
  },
  props: {
    taxData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    'height-fade': heightFade,
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const { taxesOptions } = useTaxesList();

    const submitData = () => {
      isLoading.value = true;
      emit("saveData");
    };
    const show = computed(() => {
      const type = props.taxData.type
      if(type) return true
      else return false
    });

    return {
      submitData,
      taxesOptions,
      isLoading,
      show,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
