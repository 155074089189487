<template>
  <component :is="taxData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="taxData === undefined">
      <h4 class="alert-heading">Erreur de récupération des données de taxes</h4>
      <div class="alert-body">
        Pas de Taxe trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-taxes-list' }">
          la liste des taxes
        </b-link>
        pour d'autres taxes.
      </div>
    </b-alert>

    <b-tabs v-if="taxData" pills>
      <!-- Tab: Général -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Création Taxe</span>
        </template>
        <handle-tax-tab-general
          class="mt-2 pt-75"
          :tax-data.sync="taxData"
          @saveData="onSubmit(taxData)"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import HandleTaxTabGeneral from "./HandleTaxTabGeneral.vue";
import useTaxesList from "../taxes-list/useTaxesList";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "HandleTaxes",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    HandleTaxTabGeneral,
  },
  setup() {
    // Use toast
    const toast = useToast();
    const { onSubmit } = useTaxesList();

    const blanktaxData = {
      name: "",
      rate: "",
      type: null,
    };

    const taxData = ref(JSON.parse(JSON.stringify(blanktaxData)));

    const fetchTax = () => {
      store
        .dispatch("tax/fetchTax", { id: router.currentRoute.params.id })
        .then((tax) => {
          taxData.value = tax;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            taxData.value = undefined;
          }
        });
    };

    onMounted(() => {
      if (router.currentRoute.params.id) {
        fetchTax();
      }
    });

    return {
      taxData,
      onSubmit,
    };
  },
};
</script>

<style></style>
