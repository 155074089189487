import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useTaxesList() {
  // Use toast
  const toast = useToast();
  const { route, router } = useRouter();

  const refTaxesListTable = ref(null);

  // filter options
  const taxesOptions = [
    {label: "Facture", key: "facture"},
    {label: "Salaire", key: "salaire"},
  ];

  // Table Handlers
  const tableColumns = [
    { label: "libellé", key: "name", sortable: true },
    { label: "Pourcentage", key: "rate", sortable: true },
    { label: "Type de taxe", key: "type" },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalTaxes = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const typeFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refTaxesListTable.value
      ? refTaxesListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTaxes.value,
    };
  });

  const refetchData = () => {
    refTaxesListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, typeFilter],
    () => {
      refetchData();
    }
  );

  const fetchTaxesList = (ctx, callback) => {
    store
      .dispatch("tax/fetchTaxesList", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        type: typeFilter.value,
      })
      .then((response) => {
        const { taxes, total } = response;
        
        callback(taxes);
        totalTaxes.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de la récupération des taxes",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const addTax = (data) => {
    store
      .dispatch("tax/addTax", data)
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "La taxe a été ajoutée avec succès.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        router.push({ name: "apps-taxes-list" });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Une erreur est survenue lors de l'ajout de la taxe",
            icon: "AlertTriangleIcon",
            variant: "error",
          },
        });
      });
  };

  const updateTax = (data) => {
    store
      .dispatch("tax/updateTax", data)
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "La taxe a été mise à jour avec succès.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        router.push({ name: "apps-taxes-list" });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Une erreur est survenue lors de la modification de la taxe",
            icon: "AlertTriangleIcon",
            variant: "error",
          },
        });
      });
  };

  const deleteTax = async (taxID) => {
    const { data, status } = await store.dispatch("tax/destroyTax", {
      id: taxID,
    });
    if (status != 200) {
      toast({
        component: ToastificationContent,
        props: {
          title: data.error,
          icon: "AlertTriangleIcon",
          variant: "error",
        },
      });
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: "La taxe a été supprimée avec succès.",
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      refetchData();
    }
  };

  const onSubmit = (data) => {
    if (data.id) {
      updateTax(data);
    } else {
      addTax(data);
    }
  };

  return {
    fetchTaxesList,
    tableColumns,
    perPage,
    currentPage,
    totalTaxes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTaxesListTable,
    refetchData,
    deleteTax,
    onSubmit,

    typeFilter,
    taxesOptions,
  };
}
